<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>Steps</h1>
                    <a href="" @click.prevent="whatToShow = whatToShow === 'add' ? '' : 'add'" class="btn" style="float: left;">add new Step</a>
                    <router-link class="btn" to="/dispute/configurator" active-class="active" style="float: right;">Back</router-link>
                </div>
            </div>
            <div class="row" style="margin-top: 40px;">
                <div class="col-2" v-for="step in steps">
                    <a href="" style="max-width: 100%;" class="btn" @click.prevent="getStepInfo(step.id)">{{step.action}}</a>
                </div>
            </div>
            <form @submit.prevent="addStep(newStep)" class="row" v-if="whatToShow === 'add'" style="margin-top: 40px;">
                <div class="col-2">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="newStep.action" placeholder="Enter Action">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="newStep.numberOfDays" placeholder="Enter Number of days">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="newStep.status" placeholder="Enter Status">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="newStep.orderNumber" placeholder="Enter Order num">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <b-dropdown :text="!newStep.template ? 'Select Template' : newStep.template">
                            <b-dropdown-item
                                v-for="(item, index) in templates"
                                :key="index"
                                @click="newStep.template = item"
                            >
                                {{item}}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="col-2">
                    <button type="submit" style="width: 100%;" class="btn">submit</button>
                </div>
                <div class="col-8"></div>
                <div class="col-2">
                    <button type="button" @click.prevent="whatToShow = ''" style="width: 100%;" class="btn">close</button>
                </div>
            </form>
            <form @submit.prevent="updateStep()" class="row" v-if="whatToShow === 'update'" style="margin-top: 40px;">
                <div class="col-2">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="updatedStep.action" placeholder="Enter Action">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="updatedStep.numberOfDays" placeholder="Enter number of days">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="updatedStep.status" placeholder="Enter Status">
                    </div>
                </div>
                <div class="col-2">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="updatedStep.orderNumber" placeholder="Enter Order num">
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <b-dropdown :text="updatedStep.template">
                            <b-dropdown-item
                                    v-for="(item, index) in templates"
                                    :key="index"
                                    @click="updatedStep.template = item"
                            >
                                {{item}}
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <div class="col-2">
                    <button type="submit" style="width: 100%;" class="btn">update</button>
                </div>
                <div class="col-2">
                    <button type="button" @click.prevent="deleteStep(updatedStep)" style="width: 100%;" class="btn">delete</button>
                </div>
                <div class="col-6"></div>
                <div class="col-2">
                    <button type="button" @click.prevent="whatToShow = ''" style="width: 100%;" class="btn">close</button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
	export default {
		name: "ConfiguratorSteps",
        data() {
			return {
				whatToShow: '',
                steps: [],
                newStep: {
	                numberOfDays: '',
	                action: '',
	                status: '',
	                template: '',
	                orderNumber: '',
                },
                updatedStep: {},
				templates: []
            }
        },
        mounted() {
			this.getTemplates()
            this.getSteps()
        },
        methods: {
			getTemplates() {
				disputeAxios
					.get('/api/template-editor/list')
					.then(({data}) => {
						this.templates = data.templates
					})
					.catch(err => console.error(err))
            },
			getSteps() {
				disputeAxios
					.get('/api/configurations/steps')
                    .then(({data}) => this.steps = data.sort((a, b) => a.orderNumber > b.orderNumber))
                    .catch(err => console.error(err))
            },
            addStep(data) {
				disputeAxios
		            .post(`/api/configurations/step/add`, data)
		            .then(({data}) =>{
			            this.steps.push(data)
                        this.whatToShow = ''
			            this.newStep = {
				            numberOfDays: '',
                            action: '',
                            status: '',
                            template: '',
                            orderNumber: '',
			            }
                    })
		            .catch(err => {
		            	if (err.response.data.code === 2017) {
				            if (err.response.data.message) {
                                EventBus.$emit('showAlertModal', err.response.data.message)
                            }
		            	}
			            console.error(err)
                    })
            },
            getStepInfo(id) {
	            disputeAxios
		            .get(`/api/configurations/step/get/${id}`)
		            .then(({data}) => {
		            	this.updatedStep = data
                        this.whatToShow = 'update'
                    })
		            .catch(err => console.error(err))
            },
            updateStep() {
				let data = {
					numberOfDays: this.updatedStep.numberOfDays,
					action: this.updatedStep.action,
					status: this.updatedStep.status,
					template: this.updatedStep.template,
					orderNumber: this.updatedStep.orderNumber
                }
	            disputeAxios
		            .patch(`/api/configurations/step/update/${this.updatedStep.id}`, data)
		            .then(({data}) => {
			            if (data.code === 2004) {
				            let step = this.steps.find(item => item.id === this.updatedStep.id);
				            this.steps[this.steps.indexOf(step)] = this.updatedStep
				            this.$forceUpdate()
				            this.whatToShow = ''
				            EventBus.$emit('showAlertModal', 'Saved')
			            }
                    })
		            .catch(err => {
			            if (err.response.data.code === 2017) {
				            if (err.response.data.message) {
                                EventBus.$emit('showAlertModal', err.response.data.message)
                            }
			            }
			            console.error(err)
                    })
            },
            deleteStep({id}) {
	            disputeAxios
		            .delete(`/api/configurations/step/delete/${id}`)
		            .then(({data}) => {
                        if (data.code === 2000) {
	                        let step = this.steps.find(item => item.id === id);
	                        this.steps.splice(this.steps.indexOf(step), 1)
	                        this.$forceUpdate()
	                        EventBus.$emit('showAlertModal', 'Deleted')
	                        this.whatToShow = ''
                        }
                    })
		            .catch(err => console.error(err))
            }
		}
	}
</script>

<style scoped>

</style>